// Generated by Framer (3ae0a1a)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qg8m2oyp1"];

const serializationHash = "framer-Ul4Jp"

const variantClassNames = {qg8m2oyp1: "framer-v-1wc7je7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qg8m2oyp1", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 875, intrinsicWidth: 426.5, pixelHeight: 1750, pixelWidth: 853, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/FQ6r2Er2ToDpNQ9zuuUPkN97nQs.webp?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/FQ6r2Er2ToDpNQ9zuuUPkN97nQs.webp 853w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1wc7je7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qg8m2oyp1"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ul4Jp.framer-1gw7uuk, .framer-Ul4Jp .framer-1gw7uuk { display: block; }", ".framer-Ul4Jp.framer-1wc7je7 { height: 482px; overflow: visible; position: relative; width: 235px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 482
 * @framerIntrinsicWidth 235
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergpwdkY77e: React.ComponentType<Props> = withCSS(Component, css, "framer-Ul4Jp") as typeof Component;
export default FramergpwdkY77e;

FramergpwdkY77e.displayName = "Newsletter_Iphone_1";

FramergpwdkY77e.defaultProps = {height: 482, width: 235};

addFonts(FramergpwdkY77e, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})